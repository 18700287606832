import { baseURL, authURL } from './consts';
import { logout } from './login';
import { showError } from './notification';

export const dataAPI = axios.create({
  baseURL: baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true
});

export const authAPI = axios.create({
  baseURL: authURL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true
})

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

const apiInterceptorErrorHandler = api => {
  return (error => {
    const originalRequest = error.config;

    console.debug('intercepted error:', error);
    // Check if the error is a 401 Unauthorized and if the original request is not the refresh token endpoint
    if (error.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then(() => {
            return api(originalRequest);
          })
          .catch(err => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      // Call the token refresh endpoint
      return new Promise((resolve, reject) => {
        authAPI
          .post('/token/refresh', {}, { withCredentials: true })
          .then(() => {
            processQueue();
            resolve(api(originalRequest));
          })
          .catch(err => {
            logout();
            reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }

    console.log(error);
    showError(error.message);
    return Promise.reject(error);
  });
}

// Add a response interceptor
dataAPI.interceptors.response.use(
  response => response,
  apiInterceptorErrorHandler(dataAPI)
);

authAPI.interceptors.response.use(
  response => response,
  apiInterceptorErrorHandler(authAPI)
);
