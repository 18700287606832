import { moveRight, moveLeft } from './helpers';

export const showError = (error) => {
  const container = $('.notification-container');
  const el = $('<div></div>').
    addClass('.notification alert alert-danger').
    text(error);

  container.append(el);

  moveLeft(el);

  setTimeout(() => { moveRight(el) }, 5000);
}

$('.notification-container').on('click', '.notification', event => {
  const el = $(event.currentTarget);
  console.log('notif clicked')
  moveRight(el);
});
